import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const AlertIcon = ({ children, className }) => {
  const element = React.Children.only(children)

  return React.cloneElement(element, {
    className: classnames(
      'ln-c-alert__icon',
      className,
      element.props && element.props.className,
    ),
  })
}

AlertIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

AlertIcon.defaultProps = {
  children: undefined,
  className: undefined,
}

AlertIcon.displayName = 'AlertIcon'

export default AlertIcon
