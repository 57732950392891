import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TooltipMessage = ({
  children,
  element,
  innerRef,
  className,
  arrow,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames('ln-c-tooltip__message', className)}
      ref={innerRef}
    >
      {children}
      {arrow}
    </Element>
  )
}

TooltipMessage.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.objectOf,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** The arrow element with positioning applied */
  arrow: PropTypes.element,
  /** Ref callback for message element */
  innerRef: PropTypes.func,
}

TooltipMessage.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  arrow: undefined,
  innerRef: undefined,
}

TooltipMessage.displayName = 'TooltipMessage'

export default TooltipMessage
