import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Link = ({
  element,
  children,
  className,
  bare,
  cta,
  color,
  skip,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-link',
        bare && 'ln-c-link--bare',
        cta && 'ln-c-link--cta',
        color && `ln-c-link--${color}`,
        skip && 'ln-c-link--skip',
        className,
      )}
    >
      {children}
    </Element>
  )
}

Link.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Only shows the underline on focus or hover */
  bare: PropTypes.bool,
  /** Applies the button type styling  */
  cta: PropTypes.bool,
  /** Sets the link colour */
  color: PropTypes.string,
  /** Sets skip link behaviour so that it only shows on keyboard focus */
  skip: PropTypes.bool,
}

Link.defaultProps = {
  element: 'a',
  children: undefined,
  className: undefined,
  bare: false,
  cta: false,
  color: undefined,
  skip: false,
}

Link.displayName = 'Link'

export default Link
