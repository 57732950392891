import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import TextArea from './TextArea'
import FormGroup from './FormGroup'
import propTypes from './utils/fieldPropTypes'

/**
 * @see Combines [`TextArea`](#/Components/Inputs?id=textarea) and [`FormGroup`](#/Components/Form?id=formgroup-1)
 * for a more feature rich component. See their component demos for extended functionality.
 */
class TextAreaField extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.defaultValue || '' }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const { onChange } = this.props
    this.setState({ value: event.target.value })

    if (onChange) {
      onChange(event)
    }
  }

  render() {
    const { value } = this.state
    const {
      characterCount,
      className,
      error,
      hideLabel,
      info,
      label,
      labelElement,
      name,
      optional,
      required,
      validationFirst,
      warning,
      ...props
    } = this.props
    const counterId = `${name}Counter`

    return (
      <FormGroup
        className={className}
        error={error}
        hideLabel={hideLabel}
        info={info}
        label={label}
        labelElement={labelElement}
        name={name}
        optional={optional}
        required={required}
        validationFirst={validationFirst}
        warning={warning}
      >
        {({ 'aria-describedby': describedBy, ...inputProps }) => (
          <Fragment>
            <TextArea
              {...inputProps}
              aria-describedby={
                describedBy || characterCount
                  ? `${describedBy || ''} ${characterCount ? counterId : ''}`
                  : undefined
              }
              {...props}
              onChange={this.handleChange}
              value={value}
            />
            {characterCount && props.maxLength && (
              <p
                id={counterId}
                className="ln-u-margin-ends"
                aria-live="polite"
                aria-atomic="true"
              >
                {value.length}
                <span className="ln-u-visually-hidden"> out of </span>
                <span aria-hidden="true"> / </span>
                {props.maxLength}
              </p>
            )}
          </Fragment>
        )}
      </FormGroup>
    )
  }
}

TextAreaField.getDerivedStateFromProps = (props, state) =>
  props.value !== undefined && props.value !== state.value
    ? { value: props.value }
    : null

TextAreaField.propTypes = {
  ...propTypes,
  characterCount: PropTypes.bool,
}

TextAreaField.defaultProps = {
  characterCount: false,
}

TextAreaField.displayName = 'TextAreaField'

export default TextAreaField
