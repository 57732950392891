import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InfoCircle, Info } from '@jsluna/icons'

const TooltipIcon = ({
  children,
  className,
  element,
  outlined,
  onClick,
  tooltipId,
  label,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames('ln-c-tooltip__icon', className)}
      onClick={onClick}
      aria-label={label}
      aria-controls={tooltipId}
    >
      {children ||
        (outlined ? <Info size="large" /> : <InfoCircle size="large" />)}
    </Element>
  )
}

TooltipIcon.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.objectOf,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipId: PropTypes.string,
}

TooltipIcon.defaultProps = {
  element: 'button',
  children: undefined,
  className: undefined,
  label: 'More information',
  outlined: false,
  onClick: e => e.preventDefault(),
  tooltipId: undefined,
}

TooltipIcon.displayName = 'TooltipIcon'

export default TooltipIcon
