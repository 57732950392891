import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'

import { childMatchesType } from '@jsluna/utils'

import TooltipIcon from './TooltipIcon'
import TooltipMessage from './TooltipMessage'

const Tooltip = ({
  open,
  element,
  children,
  className,
  tooltipId,
  position,
  priority,
  boundariesElement,
  escapeWithReference,
  popper,
  outlined,
  large,
  label,
  ...rest
}) => {
  const Element = element

  const modifiers = {
    offset: { offset: '0, 10px' },
    preventOverflow: {
      enabled: true,
      escapeWithReference,
      boundariesElement,
      priority,
    },
    ...popper.modifiers,
  }

  const filteredChildren = []
  let message

  React.Children.forEach(children, (child, index) => {
    if (childMatchesType(child, TooltipMessage)) {
      message = child
    } else if (childMatchesType(child, TooltipIcon)) {
      filteredChildren.push(
        React.cloneElement(child, {
          outlined,
          tooltipId,
          label,
          key: (child && child.props && child.props.id) || index,
        }),
      )
    } else {
      filteredChildren.push(child)
    }
  })
  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-tooltip',
        large && 'ln-c-tooltip--large',
        outlined && 'ln-c-tooltip--outlined',
        open && 'is-open',
      )}
    >
      <Manager>
        <Reference>
          {({ ref }) => <div ref={ref}>{filteredChildren}</div>}
        </Reference>
        <div
          aria-live="polite"
          aria-atomic="true"
          id={tooltipId}
          role="tooltip"
        >
          {open && (
            <Popper {...popper} placement={position} modifiers={modifiers}>
              {({ ref, style, placement, arrowProps }) =>
                React.cloneElement(message, {
                  innerRef: ref,
                  style,
                  arrow: (
                    <span
                      ref={arrowProps.ref}
                      style={arrowProps.style}
                      className={classnames(
                        'ln-c-tooltip__arrow',
                        placement && `ln-c-tooltip__arrow--${placement}`,
                      )}
                    />
                  ),
                })
              }
            </Popper>
          )}
        </div>
      </Manager>
    </Element>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.objectOf,
  ]),
  /** Show/Hide the Tooltip Message part of the component */
  open: PropTypes.bool,
  /** A unique identifier to enable screenreaders to pair the aria-describedby attribute and the tooltip message id */
  tooltipId: PropTypes.string,
  /** Initial positioning of the tooltip message. Can be positioned at the start, middle or end and left, right, top and bottom  */
  position: PropTypes.string,
  /** Provide a boundry for the position against, can be `scrollParent`, `window`, `viewport` or any DOM element */
  boundariesElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** If set to true this allows the tooltip message to overflow over any set boundries */
  escapeWithReference: PropTypes.bool,
  /** Popper JS overrides */
  popper: PropTypes.shape({
    modifiers: PropTypes.object,
  }),
  /** Use the outlined tooltip style */
  outlined: PropTypes.bool,
  /** Set the edge detection priority with popper */
  priority: PropTypes.arrayOf(PropTypes.string),
  /** Size modifier to allow for larger width of message container */
  large: PropTypes.bool,
  /** Label to decribe the trigger element, primarly used by screenreaders. */
  label: PropTypes.string,
}

Tooltip.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  open: false,
  tooltipId: undefined,
  position: 'bottom',
  boundariesElement: 'scrollParent',
  escapeWithReference: false,
  priority: ['top', 'bottom', 'left', 'right'],
  popper: {},
  outlined: false,
  large: false,
  label: undefined,
}

Tooltip.displayName = 'Tooltip'

export default Tooltip
