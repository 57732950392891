import React from 'react'

import FormOptionField from './FormOptionField'

/**
 * @see Combines [`FormOptionField`](#/Components/Form?id=formoptionfield) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 */
const CheckboxField = props => (
  <FormOptionField optionType="checkbox" {...props} />
)

CheckboxField.displayName = 'CheckboxField'

export default CheckboxField
