import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * @deprecated We’re going to be deprecating the flag component from Luna. We recommend using flexbox instead.
 */

const FlagComponent = ({
  element,
  className,
  children,
  alignment,
  nowrap,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      className={classnames(className, 'ln-o-flag__component', {
        [`ln-o-flag__component--${alignment}`]: alignment,
        'ln-o-flag__component--nowrap': nowrap,
      })}
    >
      {children}
    </Element>
  )
}

FlagComponent.displayName = 'FlagComponent'

FlagComponent.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alignment: PropTypes.oneOf(['top', 'bottom']),
  nowrap: PropTypes.bool,
}

FlagComponent.defaultProps = {
  element: 'div',
  className: undefined,
  alignment: undefined,
  nowrap: false,
}

export default FlagComponent
