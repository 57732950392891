import React from 'react'

import FormOptionField from './FormOptionField'

/**
 * @component
 * @see Combines [`FormOptionField`](#/Components/Form?id=formoptionfield) and [`FormGroup`](#/Components/Form?id=formgroup-1).
 */
const RadioButtonField = props => (
  <FormOptionField optionType="radio" {...props} />
)

RadioButtonField.displayName = 'RadioButtonField'

export default RadioButtonField
