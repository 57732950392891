import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FieldInfo = ({
  element,
  id,
  children,
  error,
  warning,
  className,
  displayFirst,
  ...rest
}) => {
  const Element = element
  return (
    <Element
      {...rest}
      id={id}
      className={classnames(className, 'ln-c-field-info', {
        'ln-c-field-info--validation-first': displayFirst,
        'ln-c-field-info--error': error || warning,
        'ln-c-field-info--extra': !error && !warning,
      })}
      role={error || warning ? 'alert' : undefined}
      aria-live={error ? 'assertive' : undefined}
    >
      {children}
    </Element>
  )
}

FieldInfo.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  className: PropTypes.string,
  displayFirst: PropTypes.bool,
}

FieldInfo.defaultProps = {
  element: 'div',
  id: undefined,
  error: false,
  warning: false,
  className: undefined,
  displayFirst: false,
}

FieldInfo.displayName = 'FieldInfo'

export default FieldInfo
