import React from 'react'
import PropTypes from 'prop-types'

const withLabel = WrappedImage => {
  /* istanbul ignore next */
  const WithLabel = ({ label, ...rest }) => (
    <WrappedImage {...rest} aria-label={label} />
  )

  WithLabel.propTypes = {
    label: PropTypes.string.isRequired,
  }

  WithLabel.displayName = 'WithLabel'

  return WithLabel
}

export default withLabel
