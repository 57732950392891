import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { filterProps } from '@jsluna/utils'

const HeaderDivider = ({ element, children, className, size, ...rest }) => {
  const Element = element

  return (
    <Element
      {...filterProps(rest, ['closeDrawer'])}
      className={classnames(
        'ln-c-header__divider',
        size && `ln-c-header__divider--${size}`,
        className,
      )}
    >
      {children}
    </Element>
  )
}

HeaderDivider.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Set sizing of spacing divider brings */
  size: PropTypes.oneOf(['small']),
}

HeaderDivider.defaultProps = {
  element: 'span',
  children: undefined,
  className: undefined,
  size: undefined,
}

HeaderDivider.displayName = 'HeaderDivider'

export default HeaderDivider
