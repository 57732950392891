import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CloseButton } from '@jsluna/button'

import AlertIcon from './AlertIcon'

const Alert = ({
  element,
  children,
  className,
  type,
  onClose,
  closeButtonElement,
  open,
  squared,
  variant,
  ...rest
}) => {
  const Element = element
  let hasIcon = false

  React.Children.forEach(children, child => {
    if (child && child.type && child.type === AlertIcon) {
      hasIcon = true
    }
  })

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-alert',
        {
          [`ln-c-alert--${type}`]: type,
          [`ln-c-alert--${variant}`]: variant,
          'ln-c-alert--dismissable': onClose,
          'ln-c-alert--squared': squared,
          'ln-u-hidden': open === false && onClose,
        },
        className,
      )}
      role="alert"
    >
      {hasIcon ? (
        <div className="ln-c-alert__wrapper">
          <div className="ln-c-alert__icon-wrapper">
            {React.Children.map(
              children,
              child => child && child.type && child.type === AlertIcon && child,
            )}
          </div>
          <div className="ln-c-alert__content">
            {React.Children.map(
              children,
              child =>
                child && (!child.type || child.type !== AlertIcon) && child,
            )}
          </div>
        </div>
      ) : (
        <div className="ln-c-alert__wrapper">
          <div className="ln-c-alert__content">{children}</div>
        </div>
      )}
      {onClose && type !== 'fixed' && (
        <CloseButton
          element={closeButtonElement}
          label="Dismiss message"
          onClick={onClose}
          className="ln-c-alert__dismiss-button"
        />
      )}
    </Element>
  )
}

Alert.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  /** Determines the display style used e.g. info / warning / error / success */
  variant: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  onClose: PropTypes.func,
  closeButtonElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  open: PropTypes.bool,
  squared: PropTypes.bool,
}

Alert.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  type: 'fluid',
  variant: undefined,
  onClose: undefined,
  closeButtonElement: undefined,
  open: undefined,
  squared: false,
}

Alert.displayName = 'Alert'

export default Alert
