import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@jsluna/button'

import TextInput from './TextInput'
import InputGroup from './InputGroup'
import InputControl from './InputControl'
import InputAction from './InputAction'

const Password = ({ hasToggle, ...props }) => {
  const [showText, setTextVisibility] = useState(false)

  return (
    <InputGroup>
      <InputControl>
        <TextInput {...props} type={showText ? 'text' : 'password'} />
      </InputControl>
      {hasToggle && (
        <InputAction>
          <Button onClick={() => setTextVisibility(!showText)}>
            {showText ? 'Hide' : 'Show'}
            <span className="ln-u-visually-hidden"> password</span>
          </Button>
        </InputAction>
      )}
    </InputGroup>
  )
}

Password.propTypes = {
  hasToggle: PropTypes.bool,
}

Password.defaultProps = {
  hasToggle: false,
}

Password.displayName = 'Password'

// Named export preserved for backwards compatibility
export { Password as PasswordComponent }
export default Password
