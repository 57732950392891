import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ListGroupDropdown } from '@jsluna/list'
import { childMatchesType } from '@jsluna/utils'

import HeaderItem from './HeaderItem'
import HeaderListGroup from './HeaderListGroup'

/**
 * @see Extends [HeaderItem](/#/Components/Header?id=headeritem)
 */
class HeaderNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: {},
      hover: {},
      defaultOpen: {},
    }

    this.handleDropdownClick = this.handleDropdownClick.bind(this)
    this.handleDropdownClose = this.handleDropdownClose.bind(this)
    this.handleDropdownMouseEnter = this.handleDropdownMouseEnter.bind(this)
    this.handleDropdownMouseLeave = this.handleDropdownMouseLeave.bind(this)
    this.setDropdownDefaultOpen = this.setDropdownDefaultOpen.bind(this)
  }

  componentDidMount() {
    this.setDropdownDefaultOpen()
  }

  componentDidUpdate(prevProps) {
    const { children } = this.props

    if (children !== prevProps.children) {
      this.setDropdownDefaultOpen()
    }
  }

  setDropdownDefaultOpen() {
    const { children } = this.props

    const newState = {
      defaultOpen: {},
      open: {},
    }

    React.Children.map(children, child => {
      if (childMatchesType(child, ListGroupDropdown, true)) {
        const dropdownId = child.props.id

        if (child.props.defaultOpen) {
          newState.defaultOpen[dropdownId] = true
          newState.open[dropdownId] = undefined
        } else {
          newState.defaultOpen[dropdownId] = false
        }
      }
    })

    if (Object.keys(newState.defaultOpen).length) {
      this.setState(newState)
    }
  }

  handleDropdownClick(id) {
    return e => {
      const { open: openState, defaultOpen } = this.state
      const dropdownState = openState[id]

      if (typeof dropdownState === 'undefined') {
        this.setState({ open: { [id]: !defaultOpen[id] } })
      } else {
        this.setState({ open: { [id]: !dropdownState } })
      }

      e.preventDefault()
    }
  }

  handleDropdownClose(id) {
    return () => {
      this.setState({
        open: { [id]: false },
      })
    }
  }

  handleDropdownMouseEnter(id) {
    return () => {
      this.setState({ hover: { [id]: true } })
    }
  }

  handleDropdownMouseLeave(id) {
    return () => {
      this.setState({ hover: { [id]: false } })
    }
  }

  render() {
    const {
      element,
      children,
      className,
      label,
      tabBar,
      bottomBar,
      drawer,
      type,
      size,
      spaced,
      color,
      openDropdownOnHover,
      closeDrawer,
      ...rest
    } = this.props
    const { open, hover } = this.state

    return (
      <HeaderItem
        {...rest}
        element={element}
        className={classnames('ln-c-header__item--nav', className)}
        tabBar={tabBar}
        bottomBar={bottomBar}
        drawer={drawer === true ? 'body' : drawer}
        aria-label={label}
        role={element !== 'nav' ? 'navigation' : undefined}
      >
        <HeaderListGroup
          listType={type}
          color={color}
          size={size}
          inline={bottomBar || tabBar === true || 'nav'}
          actionable
          spaced={spaced}
          open={open}
          hover={hover}
          openDropdownOnHover={openDropdownOnHover}
          onDropdownMouseEnter={this.handleDropdownMouseEnter}
          onDropdownMouseLeave={this.handleDropdownMouseLeave}
          onDropdownClick={this.handleDropdownClick}
          onDropdownClose={this.handleDropdownClose}
          closeDrawer={closeDrawer}
        >
          {children}
        </HeaderListGroup>
      </HeaderItem>
    )
  }
}

HeaderNav.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Aria label used to give greater context of navigation to screen reader users */
  label: PropTypes.string,
  /** Whether the nav should display as a tab bar, can be fixed or responsive */
  tabBar: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** The positioning within the drawer on smaller screen sizes */
  drawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Display as a mobile app style tab bar fixed to the bottom of the screen on smaller devices */
  bottomBar: PropTypes.bool,
  /** Styling option used by ListGroup */
  type: PropTypes.oneOf(['bare', 'full', 'pill']),
  /** Size of ListGroupItems */
  size: PropTypes.string,
  /** Whether navigation items should have spacing between them */
  spaced: PropTypes.bool,
  /** Color option used by ListGroup */
  color: PropTypes.string,
  /** Should dropdowns open on mouse over */
  openDropdownOnHover: PropTypes.bool,
  /** Method use to close the drawer menu on link click */
  closeDrawer: PropTypes.func,
}

HeaderNav.defaultProps = {
  element: 'nav',
  children: undefined,
  className: undefined,
  label: undefined,
  tabBar: false,
  drawer: undefined,
  bottomBar: false,
  type: 'full',
  size: undefined,
  spaced: false,
  color: undefined,
  openDropdownOnHover: false,
  closeDrawer: undefined,
}

HeaderNav.displayName = 'HeaderNav'

export default HeaderNav
