import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Header } from '@jsluna/header'
import { Footer } from '@jsluna/footer'
import { getComponentName } from '@jsluna/utils'

import Page from './Page'
import PageHeader from './PageHeader'
import PageBody from './PageBody'
import PageFooter from './PageFooter'

class SiteLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      headerIsStuck: false,
    }

    this.handleHeaderSticky = this.handleHeaderSticky.bind(this)
  }

  handleHeaderSticky(isSticky) {
    this.setState({
      headerIsStuck: isSticky,
    })
  }

  render() {
    const { headerIsStuck } = this.state
    const {
      children,
      className,
      customHeader,
      bodyFlushTop,
      stickyHeader,
      ...rest
    } = this.props

    const body = []
    let header
    let footer

    React.Children.forEach(children, child => {
      if (React.isValidElement(child)) {
        const { type } = child
        const componentName = getComponentName(child)

        if (type === Header || componentName === getComponentName(Header)) {
          header = child
        } else if (
          type === Footer ||
          componentName === getComponentName(Footer)
        ) {
          footer = child
        } else {
          body.push(child)
        }
      }
    })

    return (
      <Page
        className={className}
        stickyHeader={stickyHeader}
        customHeader={customHeader}
        onHeaderSticky={this.handleHeaderSticky}
        {...rest}
      >
        {header && (
          <PageHeader>
            {React.cloneElement(header, {
              isSticky: stickyHeader,
              isStuck: headerIsStuck,
            })}
          </PageHeader>
        )}
        <PageBody>{body}</PageBody>

        {footer && <PageFooter>{footer}</PageFooter>}
      </Page>
    )
  }
}

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Override to block the padding offset normally appied to HeaderBody */
  bodyFlushTop: PropTypes.bool,
  /** Flag to set whether the header being used isn't the Luna default  */
  customHeader: PropTypes.bool,
  /** Applies the fixed header behaviour from Page */
  stickyHeader: PropTypes.bool,
}

SiteLayout.defaultProps = {
  className: undefined,
  bodyFlushTop: false,
  customHeader: false,
  stickyHeader: false,
}

SiteLayout.displayName = 'SiteLayout'

export default SiteLayout
