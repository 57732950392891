import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ListGroup, ListGroupItem, ListGroupDropdown } from '@jsluna/list'
import { childMatchesType, getBestKey, Portal } from '@jsluna/utils'

import HeaderDivider from './HeaderDivider'

const LIST_TYPE = {
  ACTION: 'action',
  NAV: 'nav',
}

const HeaderListGroup = ({
  children,
  className,
  open,
  hover,
  openDropdownOnHover,
  type,
  listType,
  onDropdownMouseEnter,
  onDropdownMouseLeave,
  onDropdownClick,
  onDropdownClose,
  closeDrawer,
  ...rest
}) => {
  const hasOpenDropdown =
    !!Object.keys(open)
      .map(dropdown => open[dropdown])
      .filter(Boolean).length ||
    (openDropdownOnHover &&
      !!Object.keys(hover)
        .map(dropdown => hover[dropdown])
        .filter(Boolean).length)

  return (
    <ListGroup
      {...rest}
      className={classnames(`ln-c-header__${type}-list`, className)}
      type={listType !== 'bare' ? listType : undefined}
    >
      {React.Children.map(children, (child, index) => {
        if (childMatchesType(child, HeaderDivider)) {
          return (
            <li
              key={getBestKey(child.props, index)}
              className={
                type === LIST_TYPE.NAV
                  ? 'ln-u-display-flex'
                  : 'ln-c-header__action-item'
              }
            >
              {child}
            </li>
          )
        }

        if (childMatchesType(child, ListGroupDropdown, true)) {
          const dropdownId = child.props.id

          const hoverBehaviour = {
            onMouseEnter: onDropdownMouseEnter(dropdownId),
            onMouseLeave: onDropdownMouseLeave(dropdownId),
            hover: hover[dropdownId],
          }

          const actionProps = {
            containerClassName: 'ln-c-header__action-item',
            position: 'right',
          }

          return React.cloneElement(child, {
            key: dropdownId || index,
            className: classnames(
              child.props.className,
              type === LIST_TYPE.NAV
                ? 'ln-c-header__nav-item'
                : 'ln-c-header__action-link',
            ),
            onClick: onDropdownClick(dropdownId),
            onClose: onDropdownClose(dropdownId),
            onItemClick: closeDrawer,
            active: open[dropdownId],
            ...(type === LIST_TYPE.ACTION ? actionProps : {}),
            ...(openDropdownOnHover ? hoverBehaviour : {}),
          })
        }

        const actionProps = {
          containerClassName: 'ln-c-header__action-item',
        }

        return (
          <ListGroupItem
            {...child.props}
            key={getBestKey(child.props, index)}
            className={classnames(
              child.props.className,
              type === LIST_TYPE.NAV
                ? 'ln-c-header__nav-item'
                : 'ln-c-header__action-link',
            )}
            element={child.type}
            onClick={closeDrawer}
            {...(type === LIST_TYPE.ACTION ? actionProps : {})}
          >
            {child.props.children}
          </ListGroupItem>
        )
      })}
      <Portal exclusive id="ln-header-dropdown-overlay">
        <div
          className={classnames(
            'ln-c-header__overlay ln-c-header__overlay--dropdown',
            hasOpenDropdown && 'is-open',
          )}
          aria-hidden="true"
        >
          &nbsp;
        </div>
      </Portal>
    </ListGroup>
  )
}

HeaderListGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(LIST_TYPE).map(key => LIST_TYPE[key])),
  /** Should dropdowns open on mouse over */
  openDropdownOnHover: PropTypes.bool,
  /** Object of any open dropdown id's */
  open: PropTypes.shape({}),
  /** Object of any hovered dropdown id's */
  hover: PropTypes.shape({}),
  /** handler that accepts a dropdown id and will return an event handler to
   * set the hover state to true */
  onDropdownMouseEnter: PropTypes.func,
  /** handler that accepts a dropdown id and will return an event handler to
   * set the hover state to false */
  onDropdownMouseLeave: PropTypes.func,
  /** handler that accepts a dropdown id and will return a click event
   * handler to toggle the open state */
  onDropdownClick: PropTypes.func,
  /** handler that accepts a dropdown id and will return an event handler
   * for clickaway and blur events to close the dropdown */
  onDropdownClose: PropTypes.func,
  /** Styling option used by ListGroup */
  listType: PropTypes.oneOf(['bare', 'full', 'pill']),
  /** Method use to close the drawer menu on link click */
  closeDrawer: PropTypes.func,
}

HeaderListGroup.defaultProps = {
  children: undefined,
  className: undefined,
  type: LIST_TYPE.NAV,
  openDropdownOnHover: false,
  open: {},
  hover: {},
  onDropdownMouseEnter: () => {},
  onDropdownMouseLeave: () => {},
  onDropdownClick: () => {},
  onDropdownClose: () => {},
  listType: 'full',
  closeDrawer: undefined,
}

HeaderListGroup.displayName = 'HeaderListGroup'

export default HeaderListGroup
