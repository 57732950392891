import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const GlobalItem = ({ element, children, className, align, ...rest }) => {
  const Element = element

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-header__global-item',
        align && `ln-c-header__global-item--${align}`,
        className,
      )}
    >
      {children}
    </Element>
  )
}

GlobalItem.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Where the item should be placed along the global bar, defaults to left alignment */
  align: PropTypes.oneOf(['right', 'center']),
}

GlobalItem.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  align: undefined,
}

GlobalItem.displayName = 'HeaderGlobalItem'

export default GlobalItem
