import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Form, SearchField } from '@jsluna/form'

import HeaderItem from './HeaderItem'

/**
 * @see Extends [HeaderItem](/#/Components/Header?id=headeritem)
 */
const HeaderSearch = ({
  element,
  children,
  className,
  field,
  drawer,
  ...rest
}) => (
  <HeaderItem
    {...rest}
    element={element}
    className={classnames('ln-c-header__item--search', className)}
    drawer={drawer === true ? 'header' : drawer}
    role="search"
  >
    <SearchField
      name="search"
      label="Search"
      placeholder="Search"
      hideLabel
      {...field}
      className={classnames(field.className, 'ln-c-header__search-field')}
    />
  </HeaderItem>
)

HeaderSearch.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Props used to customise SearchField */
  field: PropTypes.shape({
    className: PropTypes.string,
  }),
  /** The positioning within the drawer on smaller screen sizes */
  drawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

HeaderSearch.defaultProps = {
  element: Form,
  children: undefined,
  className: undefined,
  field: {},
  drawer: false,
}

HeaderSearch.displayName = 'HeaderSearch'

export default HeaderSearch
