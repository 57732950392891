import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { filterProps } from '@jsluna/utils'

const HeaderTabBar = ({ element, children, className, soft, ...rest }) => {
  const Element = element

  return (
    <Element
      {...filterProps(rest, ['closeDrawer'])}
      className={classnames(
        'ln-c-header__tab-bar',
        soft && 'ln-c-header__tab-bar--soft',
        className,
      )}
    >
      {children}
    </Element>
  )
}

HeaderTabBar.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  soft: PropTypes.bool,
}

HeaderTabBar.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  soft: false,
}

HeaderTabBar.displayName = 'HeaderTabBar'

export default HeaderTabBar
