import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import HeaderNav from './HeaderNav'
import HeaderItem from './HeaderItem'
import HeaderListGroup from './HeaderListGroup'

/**
 * @see Extends [HeaderItem](/#/Components/Header?id=headeritem)
 */
class HeaderActions extends HeaderNav {
  render() {
    const {
      element,
      children,
      className,
      label,
      drawer,
      openDropdownOnHover,
      ...rest
    } = this.props
    const { open, hover } = this.state

    return (
      <HeaderItem
        {...rest}
        element={element}
        className={classnames('ln-c-header__item--action', className)}
        drawer={drawer === true ? 'header' : drawer}
        aria-label={label}
        role={element !== 'nav' ? 'navigation' : undefined}
      >
        <HeaderListGroup
          type="action"
          listType="bare"
          inline
          hard
          spaced
          open={open}
          hover={hover}
          openDropdownOnHover={openDropdownOnHover}
          onDropdownMouseEnter={this.handleDropdownMouseEnter}
          onDropdownMouseLeave={this.handleDropdownMouseLeave}
          onDropdownClick={this.handleDropdownClick}
          onDropdownClose={this.handleDropdownClose}
        >
          {children}
        </HeaderListGroup>
      </HeaderItem>
    )
  }
}

HeaderActions.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Aria label used to give greater context of navigation to screen reader users */
  label: PropTypes.string,
  /** Whether the nav should appear in the drawer on small screens */
  drawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Should dropdowns open on mouse over */
  openDropdownOnHover: PropTypes.bool,
}

HeaderActions.defaultProps = {
  element: 'nav',
  children: undefined,
  className: undefined,
  label: undefined,
  drawer: false,
  openDropdownOnHover: false,
}

HeaderActions.displayName = 'HeaderActions'

export default HeaderActions
