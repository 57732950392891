import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Link } from '@jsluna/link'
import {
  childMatchesType,
  setHasOverlay as defaultSetHasOverlay,
  filterProps,
  onKeyPress,
} from '@jsluna/utils'

import HeaderMainBar from './HeaderMainBar'
import HeaderGlobalBar from './HeaderGlobalBar'
import HeaderTabBar from './HeaderTabBar'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      parsedChildren: false,
    }

    this.handleClickAway = this.handleClickAway.bind(this)
    this.handleToggleClick = this.handleToggleClick.bind(this)
    this.parseChildren = this.parseChildren.bind(this)

    this.onKeyPress = onKeyPress([{ name: 'Escape', code: 27 }], e => {
      this.handleEscapePress(e)
    })
  }

  componentDidMount() {
    this.parseChildren()
    document.addEventListener('keydown', this.onKeyPress, { capture: true })
  }

  componentDidUpdate(prevProps) {
    const { children } = this.props

    if (children !== prevProps.children) {
      this.parseChildren()
    }
  }

  componentWillUnmount() {
    const { open } = this.state
    const { setHasOverlay } = this.props

    if (open) {
      setHasOverlay(false)
    }

    document.removeEventListener('keydown', this.onKeyPress, false)
  }

  handleToggleClick(e, forceState) {
    const { open: openState } = this.state
    const { setHasOverlay } = this.props

    const open = typeof forceState !== 'undefined' ? forceState : !openState

    this.setState({
      open,
    })

    setHasOverlay(open)
  }

  handleClickAway(e) {
    this.handleToggleClick(e, false)
  }

  handleEscapePress(e) {
    const { open } = this.state

    if (open) {
      this.handleToggleClick(e, false)
    }
  }

  parseChildren() {
    const { children } = this.props

    let hasHeaderMainBar = false
    let hasGlobalBar = false
    let hasTabBar = false

    React.Children.forEach(children, child => {
      if (childMatchesType(child, HeaderMainBar)) {
        hasHeaderMainBar = true
      }

      if (childMatchesType(child, HeaderGlobalBar)) {
        hasGlobalBar = true
      }

      if (childMatchesType(child, HeaderTabBar)) {
        hasTabBar = true
      }
    })

    this.setState({
      hasHeaderMainBar,
      hasGlobalBar,
      hasTabBar,
      parsedChildren: true,
    })
  }

  render() {
    const {
      open,
      hasHeaderMainBar: hasDetectedHeaderMainBar,
      hasGlobalBar,
      hasTabBar,
      parsedChildren,
    } = this.state

    const {
      element,
      children,
      className,
      mainId,
      isSticky,
      isStuck,
      hasGlobalBar: forceHasGlobalBar,
      hasBottomBar: forceHasBottomBar,
      hasTabBar: forceHasTabBar,
      hasMainBar: forceHasMainBar,
      hasDrawer,
      divided,
      skipLinks,
      ...rest
    } = this.props

    const hasHeaderMainBar = forceHasMainBar || hasDetectedHeaderMainBar

    const Element = element

    const headerMainBarProps = {
      onToggle: this.handleToggleClick,
      isSticky,
      isStuck,
      hasGlobalBar: forceHasGlobalBar || hasGlobalBar,
      hasBottomBar: forceHasBottomBar,
      hasTabBar: forceHasTabBar || hasTabBar,
      hasDrawer,
    }

    return (
      <Fragment>
        <Link href={`#${mainId}`} color="dark" skip>
          Skip to content
        </Link>
        {skipLinks && skipLinks.length > 0
          ? skipLinks.map(skipLink => (
              <Link
                key={skipLink.id}
                href={`#${skipLink.id}`}
                color="dark"
                skip
              >
                {skipLink.title}
              </Link>
            ))
          : null}
        <Element
          {...filterProps(rest, ['setHasOverlay'])}
          className={classnames(
            'ln-c-header',
            hasGlobalBar && 'ln-c-header--has-global-bar',
            hasTabBar && 'ln-c-header--has-tab-bar',
            divided && 'ln-c-header--divided',
            open && 'is-open',
            isSticky && 'is-sticky',
            isStuck && 'is-stuck',
            className,
          )}
        >
          {(parsedChildren || forceHasMainBar) &&
            (hasHeaderMainBar ? (
              React.Children.map(children, child =>
                childMatchesType(child, HeaderMainBar)
                  ? React.cloneElement(child, headerMainBarProps)
                  : child,
              )
            ) : (
              <HeaderMainBar {...headerMainBarProps}>{children}</HeaderMainBar>
            ))}
          <div
            className="ln-c-header__overlay ln-c-header__overlay--drawer"
            aria-hidden
            onClick={this.handleClickAway}
          />
        </Element>
      </Fragment>
    )
  }
}

Header.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** ID used for the skip link to jump to page's main content */
  mainId: PropTypes.string,
  /** Whether the header should stick to the top of the page */
  isSticky: PropTypes.bool,
  /** Flags if the user has scrolled to trigger style changes to header */
  isStuck: PropTypes.bool,
  /** Prop to override the setting of the `has-overlay` class on the `body` element which prevents scolling of content behind the modal */
  setHasOverlay: PropTypes.func,
  /** Flag to force knowledge of global bar for correct spacing if fixed, should be detectable in most circumstances */
  hasGlobalBar: PropTypes.bool,
  /** Flag to force knowledge of bottom bar for correct spacing if fixed, should be detectable in most circumstances */
  hasBottomBar: PropTypes.bool,
  /** Flag to force knowledge of tab bar for correct spacing if fixed, should be detectable in most circumstances */
  hasTabBar: PropTypes.bool,
  /** Flag to force knowledge of main bar for correct element nesting, should be detectable in most circumstances */
  hasMainBar: PropTypes.bool,
  /** Flag to force knowledge of drawer items to display toggle button, should be detectable in most circumstances */
  hasDrawer: PropTypes.bool,
  /** Add borders between bars within the header */
  divided: PropTypes.bool,
  /** Array of objects to add extra skip links to the header after the main skip link */
  skipLinks: PropTypes.arrayOf(PropTypes.object),
}

Header.defaultProps = {
  element: 'header',
  children: undefined,
  className: undefined,
  mainId: 'main-content',
  isSticky: false,
  isStuck: false,
  setHasOverlay: defaultSetHasOverlay,
  hasGlobalBar: false,
  hasBottomBar: false,
  hasTabBar: false,
  hasMainBar: false,
  hasDrawer: false,
  divided: false,
  skipLinks: [],
}

Header.displayName = 'Header'

export default Header
