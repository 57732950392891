import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CloseButton } from '@jsluna/button'
import { FlagWrapper, FlagBody, FlagComponent } from '@jsluna/grid'

import AlertIcon from './AlertIcon'

const Alert = ({
  element,
  children,
  className,
  type,
  error,
  warning,
  success,
  onClose,
  closeButtonElement,
  open,
  squared,
  ...rest
}) => {
  const Element = element
  let hasIcon = false

  React.Children.forEach(children, child => {
    if (child && child.type && child.type === AlertIcon) {
      hasIcon = true
    }
  })

  return (
    <Element
      {...rest}
      className={classnames(
        'ln-c-alert',
        {
          [`ln-c-alert--${type}`]: type,
          'ln-c-alert--error': error,
          'ln-c-alert--warning': warning,
          'ln-c-alert--success': success,
          'ln-c-alert--dismissable': onClose,
          'ln-c-alert--squared': squared,
          'ln-u-hidden': open === false && onClose,
        },
        className,
      )}
      role="alert"
    >
      {hasIcon ? (
        <FlagWrapper>
          <FlagComponent alignment="top" className="ln-u-margin-right">
            {React.Children.map(
              children,
              child => child && child.type && child.type === AlertIcon && child,
            )}
          </FlagComponent>
          <FlagBody>
            {React.Children.map(
              children,
              child =>
                child && (!child.type || child.type !== AlertIcon) && child,
            )}
          </FlagBody>
        </FlagWrapper>
      ) : (
        children
      )}
      {onClose && (
        <CloseButton
          element={closeButtonElement}
          label="Dismiss message"
          onClick={onClose}
          className="ln-c-alert__dismiss-button"
        />
      )}
    </Element>
  )
}

Alert.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  onClose: PropTypes.func,
  closeButtonElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  open: PropTypes.bool,
  squared: PropTypes.bool,
}

Alert.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  type: undefined,
  error: false,
  warning: false,
  success: false,
  onClose: undefined,
  closeButtonElement: undefined,
  open: undefined,
  squared: false,
}

Alert.displayName = 'Alert'

export default Alert
