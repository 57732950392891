import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { filterProps } from '@jsluna/utils'

const HeaderItem = ({
  element,
  children,
  className,
  tabBar,
  tabBarSoft,
  tabBarAlign,
  bottomBar,
  align,
  drawer,
  fullWidth,
  ...rest
}) => {
  const Element = element

  return (
    <Element
      {...filterProps(rest, ['closeDrawer'])}
      className={classnames(
        'ln-c-header__item',
        tabBar &&
          Array.isArray(tabBar) &&
          tabBar.map(tab => `ln-c-header__item--tab-bar@${tab}`).join(' '),
        tabBar &&
          !Array.isArray(tabBar) &&
          (typeof tabBar === 'string'
            ? `ln-c-header__item--tab-bar@${tabBar}`
            : 'ln-c-header__item--tab-bar'),
        tabBarSoft && 'ln-c-header__item--tab-bar-soft',
        tabBarAlign && `ln-c-header__item--tab-bar-${tabBarAlign}`,
        bottomBar && 'ln-c-header__item--bottom-bar',
        align && `ln-c-header__item--${align}`,
        drawer &&
          `ln-c-header__drawer-item ln-c-header__drawer-item--${drawer}`,
        fullWidth && 'ln-c-header__item--full-width',
        className,
      )}
    >
      {children}
    </Element>
  )
}

HeaderItem.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Whether the header item should display as a tab bar, can be fixed or responsive */
  tabBar: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
  ]),
  /** Display as a mobile app style tab bar fixed to the bottom of the screen on smaller devices */
  bottomBar: PropTypes.bool,
  /** Where the item should be positioned along the header, defaults to left alignment */
  align: PropTypes.oneOf(['right', 'center']),
  /** The positioning within the drawer on smaller screen sizes */
  drawer: PropTypes.oneOfType([
    PropTypes.oneOf(['header', 'body', 'footer']),
    PropTypes.bool,
  ]),
  /** Forces the item to take up all available space within the header */
  fullWidth: PropTypes.bool,
  /** Uses a larger version of the tab bar with padding surrounding the content */
  tabBarSoft: PropTypes.bool,
  /** Set the tab bar alignment, defaults to center */
  tabBarAlign: PropTypes.oneOf(['left', 'right']),
}

HeaderItem.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  tabBar: false,
  bottomBar: false,
  align: undefined,
  drawer: false,
  fullWidth: false,
  tabBarSoft: false,
  tabBarAlign: undefined,
}

HeaderItem.displayName = 'HeaderItem'

export default HeaderItem
