import React from 'react'

import Text from './Text'

const getDisplayName = typeStyle =>
  `${typeStyle.charAt(0).toUpperCase()}${typeStyle
    .substring(1)
    .replace('-', '')}`

export default typeStyle => {
  const Composed = props => <Text typeStyle={typeStyle} {...props} />
  Composed.displayName = getDisplayName(typeStyle)
  return Composed
}
